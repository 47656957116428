.monthlySelectorContainer {
  width: fit-content;

  margin-left: auto;
  /* Optional: margin below for spacing */
}

.tableLoadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

/* Content container for fade-in animation */
.content {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.loaded {
  opacity: 1;
}

/* Centered loading or error container */
.centeredLoading {
  text-align: center;
  padding: 50px;
}

/* PortalCard container styles */
.portalCardContainer {
  padding: 20px;
  position: relative;
}

/* Full width table with collapsed borders */
.fullWidthTable {
  width: 100%;
  border-collapse: collapse;
}

/* Header cell styling for table */
.tableHeaderCell {
  border-bottom: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

/* Standard cell styling for table rows */
.tableCell {
  border-bottom: 1px solid #eee;
  padding: 8px;
}

/* Styling for "No users found" cell */
.noUsersCell {
  padding: 20px;
  text-align: center;
  color: #68b93f;
  font-weight: 600;
  font-size: 16px;
}

/* Even table row background */
.evenRow {
  background-color: #f9f9f9;
}

/* Odd table row background */
.oddRow {
  background-color: #fff;
}

/* Optional header row styling */
.tableRowHeader {
  text-align: left;
}

/* Container for the RankMedal element */
.rankMedalContainer {
  position: relative;
  left: -7px;
  height: 2px;
  width: 20px;
}

/* Container for the Badge element */
.badgeContainer {
  position: relative;
  left: -7px;
}

.diamondContainer{
  position: relative;
  left: -15px;
}
