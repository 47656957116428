/* Container and Card */
.container {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Tabs styling */
.tabs {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.tab {
  padding: 12px 18px;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.activeTab {
  border-bottom: 2px solid #333;
  transform: translateY(2px);
}

/* Toggle button styling */
.toggleContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 4px;
  width: 390px;
}

.toggleButton {
  flex: 1;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.toggleActive {
  background-color: #333;
  color: #fff;
}

.toggleInactive {
  background-color: #fff;
  color: #333;
}

/* Chart Wrapper for fade transitions */
.chartWrapper {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.chartWrapper.visible {
  opacity: 1;
}

/* Table styling for category keys */
.tableContainer {
  margin-top: 20px;
  overflow-x: auto;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 10px;
  max-height: 500px;
  overflow-y: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.th {
  border-bottom: 2px solid #e0e0e0;
  padding: 12px;
  font-weight: 600;
  color: #333;
}

.checkboxColumn {
  text-align: center;
}

.td {
  border-bottom: 1px solid #e0e0e0;
  padding: 12px;
  color: #555;
}

.tableRow {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tableRow:hover {
  background-color: #f5f5f5;
}



@media (max-width: 470px) {
  .toggleContainer {
    flex-direction: column;
    align-items: center;
    padding: 10px;;
  }
  .toggleButton {
    width: 100%;
    max-width: 300px;
    margin-bottom: 0.5rem;

    font-size: 0.9rem;
  }
}

